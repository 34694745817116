import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {ParsedUrlQuery} from "node:querystring";

interface FilterState {
  isOpen: boolean,
  isDetailOpen: boolean,
  key: string,
  query: ParsedUrlQuery
}

const initialState: FilterState = {
  isOpen: false,
  isDetailOpen: false,
  key: '',
  query: {}
}

export const filterSlice = createSlice({
  name: 'filterState',
  initialState,
  reducers: {
    openFilter(state) {
      state.isOpen = true
    },

    closeFilter(state) {
      state.isOpen = false
    },

    openDetail(state) {
      state.isDetailOpen = true
    },

    closeDetail(state) {
      state.isDetailOpen = false
    },

    setQuery(state, action: PayloadAction<Record<string, string[]>>) {
      state.query = action.payload;
    }
  }
})

export const {
  openFilter,
  closeFilter,
  openDetail,
  closeDetail,
  setQuery
} = filterSlice.actions;
export default filterSlice.reducer;
