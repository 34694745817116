import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

interface WishlistState {
  isOpen: boolean,
  isLoading: boolean,
  lists: any[],
  orderNumbers: any[],
  orderNumber: any,
  isListOpen: boolean
}

const initialState: WishlistState = {
  isOpen: false,
  isLoading: false,
  lists: [],
  orderNumbers: [],
  orderNumber: null,
  isListOpen: false
}

export const wishlistSlice = createSlice({
  name: 'wishlistState',
  initialState,
  reducers: {
    setLists(state, action: PayloadAction<any>) {
      state.lists = action.payload;

      state.orderNumbers = state.lists.flatMap((list: any) => {
        if (list.items.length) {
          return list.items.map((item: any) => {
            return item.orderNumber;
          })
        }
        else {
          return [];
        }
      });
    },

    openWishlist(state) {
      state.isOpen = true;
    },

    closeWishlist(state) {
      state.isOpen = false;
    },

    setOrderNumber(state, action: PayloadAction<string>) {
      state.orderNumber = action.payload;
    },

    showListOverlay(state) {
      state.isListOpen = true
    },

    closeListOverlay(state) {
      state.isListOpen = false
    },
  }
})

export const {
  setLists,
  openWishlist,
  closeWishlist,
  setOrderNumber,
  showListOverlay,
  closeListOverlay
} = wishlistSlice.actions;
export default wishlistSlice.reducer;
