import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

interface AdState {
  isOpen: boolean,
  data: any
}

const initialState: AdState = {
  isOpen: false,
  data: null
}

export const adSlice = createSlice({
  name: 'adState',
  initialState,
  reducers: {
    setOpen(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },

    setData(state, action: PayloadAction<any>) {
      state.data = action.payload;
    },
  }
})

export const {
  setOpen,
  setData
} = adSlice.actions;
export default adSlice.reducer;
