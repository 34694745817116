import {configureStore, Store} from '@reduxjs/toolkit'
import productSelectorReducer from "../stores/product/productSelectorSlice";
import {createWrapper} from 'next-redux-wrapper';
import formValidatorReducer from "./form/formValidatorSlice";
import cartReducer from "./cart/cartSlice";
import customerReducer from "./customer/customerSlice";
import premiumReducer from "./premium/premiumSlice";
import perfectPointReducer from "./perfectpoint/perfectPointSlice";
import searchReducer from "./search/searchSlice";
import wishlistReducer from "./wishlist/wishlistSlice";
import loginOverlayReducer from "./loginOverlay/loginOverlaySlice";
import navigationReducer from "./navigation/navigationSlice";
import filterReducer from "./filter/filterSlice";
import accountNavigationReducer from "./account/accountNavigationSlice";
import messageReducer from "./message/messageSlice";
import adReducer from "./bxAd/adSlice";
import brandReducer from "./brand/brandSlice";
import promoBannerReducer from "./promoBanner/promoBannerSlice";

export const store = () => configureStore({
  reducer: {
    productSelector: productSelectorReducer,
    formValidator: formValidatorReducer,
    cartState: cartReducer,
    customerState: customerReducer,
    premiumState: premiumReducer,
    perfectPointState: perfectPointReducer,
    searchState: searchReducer,
    wishlistState: wishlistReducer,
    loginOverlayState: loginOverlayReducer,
    navigationStore: navigationReducer,
    filterState: filterReducer,
    accountNavigationStore: accountNavigationReducer,
    messageStore: messageReducer,
    adState: adReducer,
    brandStore: brandReducer,
    promoBannerState: promoBannerReducer
  },
})

export type State = ReturnType<ReturnType<typeof store>["getState"]>;
export const wrapper = createWrapper<Store>(store)
