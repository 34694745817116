import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface MessageState {
  show: boolean;
  success: boolean;
  error: boolean;
  warning: boolean;
  text: any;
  autoHide: boolean;
}

const initialState: MessageState = {
  show: false,
  success: false,
  error: false,
  warning: false,
  text: null,
  autoHide: true
}

export const messageSlice = createSlice({
  name: 'messageState',
  initialState,
  reducers: {
    enableSuccess(state, action: PayloadAction<string>) {
      state.warning = false;
      state.error = false;
      state.show = true;
      state.success = true;
      state.text = action.payload;
    },

    enableError(state, action: PayloadAction<string>) {
      state.warning = false;
      state.success = false;
      state.show = true;
      state.error = true;
      state.text = action.payload;
    },

    showWarning(state, action: PayloadAction<string>) {
      state.success = false;
      state.error = false;
      state.show = true;
      state.warning = true;
      state.text = action.payload;
    },

    setShow(state, action: PayloadAction<boolean>) {
      state.show = action.payload;
    },

    setSuccess(state, action: PayloadAction<boolean>) {
      state.success = action.payload;
    },

    setError(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },

    setText(state, action: PayloadAction<string>) {
      state.text = action.payload;
    },

    setAutoHide(state, action: PayloadAction<boolean>) {
      state.autoHide = action.payload;
    },
  }
})

export const {
  enableSuccess,
  enableError,
  setShow,
  setSuccess,
  setError,
  setText,
  setAutoHide,
  showWarning
} = messageSlice.actions;
export default messageSlice.reducer;
