import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface NavigationState {
  isOpen: boolean,
  categories: any,
  category: any,
  parent: any
}

const initialState: NavigationState = {
  isOpen: false,
  categories: [],
  category: null,
  parent: null
}

export const navigationSlice = createSlice({
  name: 'navigationStore',
  initialState,
  reducers: {
    openMenu: (state) => {
      state.isOpen = true
    },

    closeMenu: (state) => {
      state.isOpen = false
    },

    setCategories: (state, action: PayloadAction<any>) => {
      state.categories = action.payload;
    },

    setCategory: (state, action: PayloadAction<any>) => {
      state.category = action.payload;
    },

    setParent: (state, action: PayloadAction<any>) => {
      state.category = action.payload;
    },
  }
})

export const {
  openMenu,
  closeMenu,
  setCategories,
  setCategory,
  setParent
} = navigationSlice.actions;
export default navigationSlice.reducer;
