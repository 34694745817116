import axios from "axios";

export default function apiInternal() {
  return axios.create({
    baseURL: process.env.INTERNAL_URL,
    withCredentials: true,
    headers: {
      Authorization : `Bearer ${process.env.INTERNAL_TOKEN}`
    }
  })
}