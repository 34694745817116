import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface PerfectPointState {
  perfectPoint: any
}

const initialState: PerfectPointState = {
  perfectPoint: null
}

export const perfectPointSlice = createSlice({
  name: 'perfectPointState',
  initialState,
  reducers: {
    setPerfectPoint(state, action: PayloadAction<any>) {
      state.perfectPoint = action.payload;
    },
  }
})

export const {setPerfectPoint} = perfectPointSlice.actions;
export default perfectPointSlice.reducer;
