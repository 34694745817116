import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface ProductSelectorState {
  product: any,
  quantity: number,
  detailQuantity: number
}

const initialState: ProductSelectorState = {
  product: null,
  quantity: 1,
  detailQuantity: 1,
}

export const productSelectorSlice = createSlice({
  name: 'productSelector',
  initialState,
  reducers: {
    setSelectedProduct: (state, action: PayloadAction<any>) => {
      state.product = action.payload
    },

    setSelectedQuantity: (state, action: PayloadAction<number>) => {
      state.quantity = action.payload;
    },

    setDetailQuantity: (state, action: PayloadAction<number>) => {
      state.detailQuantity = action.payload;
    },
  }
})

export const {setSelectedProduct, setSelectedQuantity, setDetailQuantity} = productSelectorSlice.actions;
export default productSelectorSlice.reducer;
