import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface CustomerState {
  customer: any
}

const initialState: CustomerState = {
  customer: {}
}

export const customerSlice = createSlice({
  name: 'customerState',
  initialState,
  reducers: {
    setCustomer(state, action: PayloadAction<any>) {
      state.customer = action.payload;
    }
  }
})

export const {setCustomer} = customerSlice.actions;
export default customerSlice.reducer;
