import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface AccountNavigationState {
  isOpen: boolean,
}

const initialState: AccountNavigationState = {
  isOpen: false,
}

export const accountNavigationSlice = createSlice({
  name: 'accountNavigationStore',
  initialState,
  reducers: {
    openMenu: (state) => {
      state.isOpen = true
    },

    closeMenu: (state) => {
      state.isOpen = false
    },
  }
})

export const {
  openMenu,
  closeMenu,
} = accountNavigationSlice.actions;
export default accountNavigationSlice.reducer;
