import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import moment from "moment";

interface FormValidatorState {
  validator: {
    isNameValid: boolean;
    isEmailValid: boolean;
    isTitleValid: boolean;
    isCommentValid: boolean;
    isPasswordValid: boolean;
    isDateValid: boolean;

    isFirstNameValid: boolean;
    isLastNameValid: boolean;
    isStreetValid: boolean;
    isZipCodeValid: boolean;
    isCityValid: boolean;
    isCountryValid: boolean;
    isPhoneValid: boolean;

    sendForms: {
      personal: boolean;
      emailChange: boolean;
      passwordChange: boolean;
      payment: boolean;
      shipping: boolean;
    };
    sendForm: boolean;
    inProcess: boolean;
    success: boolean;
    error: boolean;
    isLoading: boolean;

    isInvalid: string[];
  }
}

const initialState: FormValidatorState = {
  validator: {
    isNameValid: true,
    isEmailValid: true,
    isTitleValid: true,
    isCommentValid: true,
    isPasswordValid: true,
    isDateValid: true,

    isFirstNameValid: true,
    isLastNameValid: true,
    isStreetValid: true,
    isZipCodeValid: true,
    isCityValid: true,
    isCountryValid: true,
    isPhoneValid: true,

    sendForms: {
      personal: false,
      emailChange: false,
      passwordChange: false,
      payment: false,
      shipping: false
    },

    sendForm: false,
    inProcess: false,
    success: false,
    error: false,
    isLoading: false,

    isInvalid: []
  }
}

export const formValidatorSlice = createSlice({
  name: 'formValidator',
  initialState,
  reducers: {
    enableAnimation(state) {
      state.validator.sendForm = true;
      state.validator.inProcess = true;
    },

    enableAnimations(state, action: PayloadAction<string>) {
      // @ts-ignore
      state.validator.sendForms[action.payload] = true;
      state.validator.inProcess = true;
    },
    disableAnimation(state) {
      state.validator.sendForm = false
      state.validator.success = false;
      state.validator.error = false;
    },

    disableAnimations(state, action: PayloadAction<string>) {
      // @ts-ignore
      state.validator.sendForms[action.payload] = false;
      state.validator.success = false;
      state.validator.error = false;
    },

    buttonResponse(state, action: PayloadAction<boolean>) {
      state.validator.inProcess = false;
      action.payload ? state.validator.success = true : state.validator.error = true;
    },

    setNameValid(state, action) {
      state.validator.isNameValid = action.payload
    },

    setEmailValid(state, action) {
      state.validator.isEmailValid = action.payload
    },

    setTitleValid(state, action) {
      state.validator.isTitleValid = action.payload
    },

    setCommentValid(state, action) {
      state.validator.isCommentValid = action.payload
    },

    setPasswordValid(state, action) {
      state.validator.isPasswordValid = action.payload
    },

    setDateValid(state, action) {
      state.validator.isDateValid = action.payload
    },

    setFirstNameValid(state, action) {
      state.validator.isFirstNameValid = action.payload
    },

    setLastNameValid(state, action) {
      state.validator.isLastNameValid = action.payload
    },

    setStreetValid(state, action) {
      state.validator.isStreetValid = action.payload
    },

    setZipCodeValid(state, action) {
      state.validator.isZipCodeValid = action.payload
    },

    setCityValid(state, action) {
      state.validator.isCityValid = action.payload
    },

    setCountryValid(state, action) {
      state.validator.isCountryValid = action.payload
    },

    setPhoneValid(state, action) {
      state.validator.isPhoneValid = action.payload
    },

    setSendForms(state, action) {
      state.validator.sendForms = action.payload
    },

    setSendForm(state, action) {
      state.validator.sendForm = action.payload
    },

    setInProcess(state, action) {
      state.validator.inProcess = action.payload
    },

    setSuccess(state, action) {
      state.validator.success = action.payload
    },

    setError(state, action) {
      state.validator.error = action.payload
    },

    setIsLoading(state, action) {
      state.validator.isLoading = action.payload
    },

    setIsInvalid(state, action) {
      state.validator.isInvalid = action.payload
    },
  }
})

export const {
  enableAnimation,
  disableAnimation,
  enableAnimations,
  disableAnimations,
  buttonResponse,
  setNameValid,
  setEmailValid,
  setTitleValid,
  setCommentValid,
  setPasswordValid,
  setDateValid,
  setFirstNameValid,
  setLastNameValid,
  setStreetValid,
  setZipCodeValid,
  setCityValid,
  setCountryValid,
  setPhoneValid,
  setSendForms,
  setSendForm,
  setInProcess,
  setSuccess,
  setError,
  setIsLoading,
  setIsInvalid
} = formValidatorSlice.actions;
export default formValidatorSlice.reducer;
