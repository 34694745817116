import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface PromoBannerState {
    bxData: any;
    loadedLanguage: string|null;
}

const initialState: PromoBannerState = {
    bxData: null,
    loadedLanguage: null
}

export const promoBannerSlice = createSlice({
    name: 'promoBannerState',
    initialState,
    reducers: {
        setBxData(state, action: PayloadAction<any>) {
            state.bxData = action.payload;
        },
        setLoadedLanguage(state, action: PayloadAction<string|null>) {
            state.loadedLanguage = action.payload;
        }
    }
});

export const { setBxData, setLoadedLanguage } = promoBannerSlice.actions;
export default promoBannerSlice.reducer;
