import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import Cart from "../../models/Cart";
import CartItem from "../../models/CartItem";
import CartPrices from "../../models/CartPrices";
import BonusVoucher from "../../models/BonusVoucher";

interface CartState {
  isOpen: boolean,
  isLoading: boolean,
  prices: any,
  cart: any,
  walleeIds: any[],
  showPaymentModal: boolean
  isWalleePayment: boolean,
  reload: boolean,
  offCanvas: any,
}

const initialState: CartState = {
  isOpen: false,
  isLoading: false,
  prices: {},
  cart: {},
  walleeIds: [],
  showPaymentModal: true,
  isWalleePayment: false,
  reload: false,
  offCanvas: {isOpen: false, isLoading: false},
}

export const cartSlice = createSlice({
  name: 'cartState',
  initialState,
  reducers: {
    setCart(state, action: PayloadAction<any>) {
      // const data = action.payload;
      state.cart = action.payload;
    },

    setPrices(state, action: PayloadAction<any>) {
      state.prices = action.payload;
    },

    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.offCanvas.isLoading = action.payload;
    },

    setReload: (state, action: PayloadAction<boolean>) => {
      state.reload = action.payload;
    },

    openCart: (state) => {
      state.offCanvas.isLoading = true;
      state.offCanvas.isOpen = true;
    },

    closeCart: (state) => {
      state.offCanvas.isLoading = false;
      state.offCanvas.isOpen = false;
    },

    setWalleeIds: (state, action: PayloadAction<any>) => {
      state.walleeIds = action.payload;
    },

    openPaymentModal: (state) => {
      state.showPaymentModal = true;
    },

    closePaymentModal: (state) => {
      state.showPaymentModal = false;
    },

    setIsWalleePayment: (state, action: PayloadAction<boolean>) => {
      state.isWalleePayment = action.payload;
    },

    setOffCanvas: (state, action: PayloadAction<any>) => {
      state.offCanvas = action.payload;
    },
  }
})

export const {
  openCart,
  closeCart,
  setCart,
  setPrices,
  setIsLoading,
  setWalleeIds,
  setReload,
  setOffCanvas,
  openPaymentModal,
  closePaymentModal,
  setIsWalleePayment
} = cartSlice.actions;
export default cartSlice.reducer;
