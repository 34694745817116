import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface BrandState {
  isOpen: boolean,
}

const initialState: BrandState = {
  isOpen: false,
}

export const brandSlice = createSlice({
  name: 'brandStore',
  initialState,
  reducers: {
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  }
})

export const {
  setIsOpen
} = brandSlice.actions;
export default brandSlice.reducer;
