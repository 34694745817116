import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface PremiumState {
  premiums: any,
  isVisible: boolean
}

const initialState: PremiumState = {
  premiums: {},
  isVisible: false
}

export const premiumSlice = createSlice({
  name: 'premiumState',
  initialState,
  reducers: {
    setPremiums(state, action: PayloadAction<any>) {
      const [premiums, isVisible] = action.payload
      state.premiums = premiums;
      state.isVisible = isVisible;
    },

    setPremiumVisible(state, action: PayloadAction<boolean>) {
      state.premiums = action.payload;
    },
  }
})

export const {setPremiums, setPremiumVisible} = premiumSlice.actions;
export default premiumSlice.reducer;
