import {createSlice} from '@reduxjs/toolkit'

interface LoginOverlayState {
  isOpen: boolean,
}

const initialState: LoginOverlayState = {
  isOpen: false,
}

export const loginOverlaySlice = createSlice({
  name: 'loginOverlayState',
  initialState,
  reducers: {
    showOverlay(state) {
      state.isOpen = true;
    },
    closeOverlay(state) {
      state.isOpen = false;
    },
  }
})

export const {
  showOverlay,
  closeOverlay,
} = loginOverlaySlice.actions;
export default loginOverlaySlice.reducer;
